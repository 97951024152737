<template>
    <div>
        <!--工具栏-->
        <div id="toolbar" ref="toolbar">
            <div id="toolsbarLeft" ref="toolsbarLeft">
                <!--标题-->
                <div @click="backClick" style="font-weight: bold; font-size: 23px; cursor: pointer; color: #ed4014" class="liefeng-icon liefeng-icon-direction-left" v-show="isBack"></div>
                <div style="font-size: 23px" class="liefeng-icon liefeng-icon-dynamic-filling" v-show="!isBack && titleShow"></div>
                <div style="margin-top: 5px; padding: 0 6px; font-size: 16px; white-space: nowrap; cursor: pointer" v-show="titleShow" ref="title" @click="backClick">
                    <slot name="title"></slot>
                </div>
                <div class="desc-text" v-if="menuDesc && menuDesc != ''">( {{menuDesc}} )</div>
            </div>
             <div ref="toolsbarLeftRight" style="padding-bottom:10px;padding-left:10px">
                    <slot name="toolsbarLeft">
                        <!--靠左工具-->
                    </slot>
                </div>
            <div id="toolsbarRight" class="toolsbarRight" ref="toolsbarRight">
                <slot name="toolsbarRight" :change-open="changeOpen">
                    <!--靠右工具-->
                </slot>
            </div>
            <!-- <div id="toolsbarMove" v-if="showMoveTips">鼠标悬停显示更多选项</div> -->
            <div id="moreRight" v-if="moreTools.moreToolsShow">
                <Button :type="moreTools.type" :icon="moreTools.icon" @click="selectQuery = true" class="moreRight-button">{{ moreTools.moreToolsName }}</Button>
            </div>
            <Drawer :title="moreTools.moreToolsName" v-model="selectQuery" :placement="moreTools.placement">
                <!--更多工具弹窗：可自定义按钮，执行this.$refs.liefengContent.showMoreTools();-->
                <slot name="moreToolsRight"></slot>
            </Drawer>
        </div>

        <!--编辑区-->
        <div id="contentarea" ref="contentarea">
            <slot name="contentArea"></slot>
        </div>
    </div>
</template>
<script>
import "/public/fontlibs/liefengiconfont.css"
export default {
    props: {
        isBack: { type: Boolean, default: false }, //显示返回按钮，执行backClick事件，父级@backClick传入
        moreTools: {
            type: Object,
            default: {
                moreToolsShow: false, //是否显示侧窗按钮
                moreToolsName: "更多", //按钮名称
                icon: "- liefeng-icon liefeng-icon-drag", //按钮图标
                type: "default", //按钮样式
                placement: "right",
            },
            moreStyle: {
                type: Object,
                default: {
                    height: "calc(100% - 55px)",
                    overflow: "auto",
                    paddingBottom: "53px",
                    position: "static",
                },
            },
        },
    },
    data() {
        return {
            titleShow: false,
            selectQuery: false,
            showMoveTips: false,
            menuDesc:''
        }
    },
    mounted() {
        if (this.$refs.title.innerText != "") {
            this.titleShow = true
        }
        // this.$nextTick(()=>{
        var rightNodes = this.$refs.toolsbarRight.childNodes
        var leftNodes = this.$refs.toolsbarLeftRight.childNodes
        if(!leftNodes.length) this.$refs.toolsbarLeftRight.style.paddingBottom = 0
        if(!rightNodes.length) this.$refs.toolsbarRight.style.padding = 0

        var contentBox = this.$refs.toolsbarRight.getElementsByClassName('button-box')[0]
        if(contentBox && contentBox[0]){
          if(contentBox[0].childNodes.length) this.$refs.toolsbarRight.style.padding = 0
        }
        if(parent && parent.vue && parent.vue.menuDesc && parent.vue.menuDesc != '' ) this.menuDesc = parent.vue.menuDesc
        // })
       
        // this.$(document).on("mouseover","#toolsbarRight",function(){
        // if($("#toolsbarRight")[0].scrollHeight>80){
        //   $("#toolsbarRight").removeClass("toolsbarRight").addClass("toolsbarRightFixed");
        // }
        // });
        // this.$(document).on("click","#toolsbarRight",function(e){
        //  e.stopPropagation();
        // });
        // this.$(document).on("click",function(){
        //   $("#toolsbarRight").removeClass("toolsbarRightFixed").addClass("toolsbarRight");
        // });
        // var resize=function(){
        //  if($("#toolsbarRight")[0].scrollHeight>80){
        //    that.showMoveTips=true;
        //  }else{
        //     that.showMoveTips=false;
        //  }
        // };
        // resize();
        // this.$(window).resize(resize);
      
    },
    methods: {
        showMoreTools() {
            this.selectQuery = true
        },
        backClick() {
            this.$emit("backClick")
        },
        // 手动点击触发
        changeOpen() {
            console.log("dianjile")
        },
    },
}
</script>
<style lang="less" scoped>
 .toolsbarRight{
  /deep/.ivu-form-item{
    margin-bottom: 0;
  }
  .button-box{
    margin: 0;
    padding: 0;
  }
 } 
</style>
<style scoped>
#toolbar {
    background: #fafafa;
    /* top: 0; */
    /* position: fixed; */
    min-width: 1000px;
    width: 100%;
    box-shadow: 0 0 1px 1px #ddd;
    z-index: 2;
}
#toolsbarLeft {
    padding: 10px;
    /* float: left; */
    display: flex;
    justify-content: flex-start;
}

#toolsbarLeft > div > .bread {
    margin-left: 74px;
}

.toolsbarRight {
    display: flex;
    width: auto !important;
    overflow: hidden;
    transition: 0.3s all;
    padding-bottom: 10px;
    padding-left: 10px;
}
#toolsbarMove {
    position: fixed;
    top: 45px;
    left: 50%;
    font-size: 12px;
    padding: 2px 10px;
    color: #fff;
    background: #c5c8ce;
    text-align: center;
    border-radius: 10px;
    z-index: 100;
}
.toolsbarRightFixed {
    transition: 0.3s all;
    /* position: fixed; */
    /* top:0;right:0; */
    z-index: 9999;
    margin: 0 10px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 150px - 40px) !important;
    /* background: #e8eaec; */
    /* border: 1px solid #c5c8ce; */
    /* border-top: 0px solid #e8eaec; */
    /* box-shadow: 0px 2px 10px #ccc; */
    /* border-radius: 0 0 10px 10px; */
}
#toolsbarRight button,
#toolsbarRight input,
#toolsbarRight div,
#toolsbarLeft button {
    margin: 0 5px 10px 0;
}

#moreRight {
    /* float: right; */
    margin: 10px;
}
#moreRight button {
    padding: 0 8px;
}

.moreRight-btn {
    border-radius: 50px;
}

#contentarea {
    /* top: calc(55px); */
    /* position: fixed; */
    /* left: 0;
    right: 0;
    bottom: 0; */
    max-width: 100%;
    padding: 10px 10px 10px 15px;
    line-height: 1.5em;
    overflow: auto;
    z-index: 1;
}
.tooldiv {
    line-height: 30px;
    text-align: right;
    white-space: nowrap;
}
.desc-text{
  margin-top: 8px;
  margin-left: 5px;
}

</style>
